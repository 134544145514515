import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Providers from './providers'
import Routes from './routes'
import { PAGE_TITLE } from './constants';

function App() {
  useEffect(() => {
    document.title = PAGE_TITLE;
  }, []);

  return (
    <Router>
      <Providers>
        <CssBaseline />
        <Routes />
      </Providers>
    </Router>
  )
}

export default App
