import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid } from '@mui/material';

export default function PageLoading({text = 'Loading...'}) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box sx={{ m: 2 }} component={CircularProgress} mr={1}/>
      <p>{text}</p>
    </Grid>
  )
}
