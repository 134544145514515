import { FC, ReactNode } from 'react'
import { useNavigate } from "react-router-dom"
import { Security } from "@okta/okta-react"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { AUTH_CLIENT_ID, AUTH_ISSUER } from './constants'
import { SubmissionsProvider } from './context/submissions'

const oktaAuth = new OktaAuth({
  clientId: AUTH_CLIENT_ID,
  issuer: AUTH_ISSUER,
  redirectUri: window.location.origin + "/login/callback",
})

const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin))
  }

  return (
    <ThemeProvider theme={theme}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <SubmissionsProvider>
          {children}
        </SubmissionsProvider>
      </Security>
    </ThemeProvider>
  )
}

export default Providers
