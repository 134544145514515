export interface Submission {
  id: string;
  category: string;
  accountName: string;
  createdAt: string;
  applicationStatus: string;
  isComplete: boolean;
  riskAppetite: boolean;
  replacementCost: boolean;
  documentReadiness: JSON;
  form140Data: JSON;
  chatHistory: any[];
}

export enum RAGVersion {
  V_1_3 = "v_1_3",
  V_1_4 = "v_1_4",
}

export interface RAGSettings {
  version: RAGVersion;
  top_k: number;
}
