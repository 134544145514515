import { createChatBotMessage } from "react-chatbot-kit";
import { BOT_NAME } from "../../../constants";

const config = {
  botName: BOT_NAME,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    chatButton: {
      backgroundColor: "#5ccc9d",
    },
  },
};

export default config;
