import styled from "@emotion/styled";
import {
  Box,
  Link,
  Typography,
  Step,
  Stepper,
  StepContent,
  StepLabel,
  StepIconProps,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const UnderwritingProcessWrapper = styled(Box)`
  border-bottom: 1px solid #878383;
  padding: 16px;
  background-color: #434756;
  overflow-y: auto;
  height: 70vh;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  ,
  &::-webkit-scrollbar-thumb {
    background-color: #847f7f;
    border-radius: 6px;
  }
`;

const steps = [
  {
    label: "Submission clearance",
    description: `Based on the submission, we received the SoV, Acord application form, and Loss Runs. `,
  },
  {
    label: "Reviewing for appetite",
    description:
      "We reviewed the LOB, geography, occupancy and TIV, to determine the level of fit.",
  },
  {
    label: "Checklist of Hazards",
    description: `The underwriter has reviewed fire hazards, flood protection, and earthquake hazards. They have confirmed the construction type is masonry and is not an issue. They sent an email to the broker asking for information on number of fire alarms on the property site`,
  },
  {
    label: "Loss Run Evaluation",
    description: `The loss runs report in the initial submission showed that this property has generated losses before. However, based on the chat history here, the underwriter researched the losses and determined they are still at an acceptable level.`,
  },
  {
    label: "Other Information Needed",
    description: `Outstanding information that still needs to be assessed includes looking at additional E&S risks to determine next steps. Contents of the underwriting process log here will be updated after information is gathered.`,
  },
];

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: ownerState.completed ? "#9BF185" : "transparent",
  color: "#fff",
  width: "1rem",
  height: "1rem",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    border: "1px solid #ffffff",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    ></ColorlibStepIconRoot>
  );
}

const CustomisedConnector = styled(StepConnector)(({ theme }) => ({
  ".MuiStepConnector-lineVertical": {
    display: "none",
  },
}));

export default function UnderwritingProcess() {
  const activeStep = 4;

  return (
    <UnderwritingProcessWrapper>
      <Link
        sx={{
          color: "white",
          textDecoration: "none",
          mt: 2,
        }}
        href="/"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIosIcon sx={{ mr: "1" }} />
          <Typography variant="h6">Back to Submissions List</Typography>
        </Box>
      </Link>
      <br />
      <Typography variant="h6">Underwriting Process</Typography>
      <Box sx={{ maxWidth: 600, margin: "auto" }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<CustomisedConnector />}
        >
          {steps.map((step, index) => (
            <Step key={index} expanded>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography style={{ fontSize: "14px" }}>
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent sx={{ pl: "10px" }}>
                <Typography style={{ fontSize: "14px", color: "#A39F9F" }}>
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </UnderwritingProcessWrapper>
  );
}
