import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledImg = styled("img")({
  maxWidth: "100%",
  height: "auto",
});

export default function PortfolioStatistics() {
  return (
    <Box>
      <Typography variant="h6" fontWeight="bold" mb={2}>
        Portfolio Statistics
      </Typography>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
      >
        <Grid item xs={12}>
          <StyledImg
            src="/images/portfolio_stats_avg_loss_ratio.png"
            alt="Average Loss Ratio"
          />
        </Grid>
        <Grid item xs={6}>
          <StyledImg
            src="/images/portfolio_stats_csp.png"
            alt="Average Loss Ratio"
          />
        </Grid>
        <Grid item xs={6}>
          <StyledImg
            src="/images/portfolio_stats_region.png"
            alt="Average Loss Ratio"
          />
        </Grid>
        <Grid item xs={12}>
          <StyledImg
            src="/images/portfolio_stats_source.png"
            alt="Average Loss Ratio"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
