import { MouseEvent, useState } from "react"
import { AppBar, Grid, IconButton, Menu, Toolbar, MenuItem } from "@mui/material"
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined'
import { useOktaAuth } from "@okta/okta-react"

const MENU_ACTION_LOGOUT = 'LOGOUT'

export default function AppToolbar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { oktaAuth, authState } = useOktaAuth()
  const name = authState?.idToken?.claims?.name || null

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = async (event: MouseEvent<HTMLElement>, action: string) => {
    setAnchorEl(null)
    switch (action) {
      case MENU_ACTION_LOGOUT:
        try {
          // Redirect the user to the Okta logout page
          await oktaAuth.signOut();
        } catch (error) {
          console.error("Logout error:", error);
        }
        break
      default:
        console.log('Unknown action')
    }
  }

  return (
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img src="/logo-copilot.png" alt="Verisk Logo" />
          <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleOutlined fontSize="large" sx={{ mr: 1 }} />
                {name}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={(event) => handleClose(event, MENU_ACTION_LOGOUT)}>Logout</MenuItem>
              </Menu>
            </div>
        </Grid>
      </Toolbar>
      </AppBar>
  )
}
