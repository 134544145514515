import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { API_URL } from "../../../constants";
import { postSubmissionChat } from "../../../api/submissions";

class ActionProvider {
  createChatBotMessage: (message: string) => any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  constructor(
    createChatBotMessage: (message: string) => any,
    setStateFunc: React.Dispatch<React.SetStateAction<any>>
  ) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  // for testing
  helloWorldHandler = () => {
    const botMessage = this.createChatBotMessage("Hi, nice to meet you!");
    this.setChatbotMessage(botMessage);
  };

  messageWebsocketHandler = (message: string) => {
    const client = new W3CWebSocket(`${API_URL}/ws`);
    client.onopen = () => {
      console.log("WebSocket Client Connected");
      const requestData = { message: message };
      client.send(JSON.stringify(requestData));
    };
    client.onclose = () => {
      console.log("WebSocket Client Closed");
    };

    client.onerror = (error: any) => {
      console.error("Connection Error:", error);
    };
    client.onmessage = (message: any) => {
      // Handle messages received from the server
      const response = JSON.parse(message.data);
      const botMessage = this.createChatBotMessage(response.message);
      this.setChatbotMessage(botMessage);
    };
  };

  async messagePostHandler(message: string, submissionId: string) {
    this.addMessageToState(message);
    this.greyOutInputBar(true);
    try {
      this.greet("Loading...");
      const response = await postSubmissionChat(message, submissionId);
      if (response.status === 200) {
        this.greet(response.data as string, true);
      } else {
        this.greet("Fail to answer", true);
      }
    } catch (error) {
      console.error("Error posting chat:", error);
      this.greet("Fail to answer", true);
    } finally {
      this.greyOutInputBar(false);
    }
  }

  setChatbotMessage(message: string) {
    this.setState((prevState: any) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }

  removeLoadingMessage = (
    prevstateArray: { messages: any[] },
    removeLoading: boolean
  ) => {
    if (removeLoading) {
      prevstateArray?.messages?.splice(
        prevstateArray?.messages?.findIndex((a) => a?.content === ""),
        1
      );
      return prevstateArray;
    } else {
      return prevstateArray;
    }
  };

  addMessageToState = (
    message: string | JSX.Element,
    removeLoading = false
  ) => {
    this.setState((prevState: any) => ({
      ...this.removeLoadingMessage(prevState, removeLoading),
      messages: [...prevState.messages, message],
    }));
  };

  greet = (botMessage: string, removeLoading = false) => {
    const message = this.createChatBotMessage(botMessage);
    this.addMessageToState(message, removeLoading);
  };

  greyOutInputBar = (isGreyedOut: boolean) => {
    const inputBar = document.getElementsByClassName(
      "react-chatbot-kit-chat-input"
    )[0] as HTMLInputElement;
    const submitButton = document.getElementsByClassName(
      "react-chatbot-kit-chat-btn-send"
    )[0]  as HTMLButtonElement;

    if (inputBar) {
      if (isGreyedOut) {
        inputBar.disabled = true;
        submitButton.disabled = true;
        inputBar.classList.add("greyed-out");
        submitButton.classList.add("greyed-out");
      } else {
        inputBar.disabled = false;
        submitButton.disabled = false;
        inputBar.classList.remove("greyed-out");
        submitButton.classList.remove("greyed-out");
      }
    }
  };
}

export default ActionProvider;
