import React from "react";
import MarkDownView from "../MarkDownView/MarkDownView";

const SystemMessage: React.FC<{ message: any }> = ({ message }) => {
    return (
      <div className="react-chatbot-kit-chat-bot-message-container">
        <div className="react-chatbot-kit-custom-avatar">
          System Auto-Generated
        </div>
        <div className="react-chatbot-kit-chat-bot-message">
          <MarkDownView markdown={message.message} />
        </div>
      </div>
    );
  };

export default SystemMessage;