import { LoginCallback } from "@okta/okta-react"
import { Route, Routes, Navigate } from "react-router-dom"
import { HomePage, SubmissionPage } from "./pages"
import { PageLoading, RequiredAuth } from "./components"

const RoutesWrapper = () => {
  return (
    <Routes>
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<PageLoading />} />}
      />
      <Route path="/" element={<RequiredAuth />}>
        <Route path="" element={<HomePage />} />
        <Route path="submissions/:submissionId" element={<SubmissionPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default RoutesWrapper
