import { API_URL } from "../constants";
import axios from "axios";

// Create an instance of Axios with a base URL
const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      // below return null; if add useeffect, the hook can't be exected correctly since ActionProvider is a class
      // const { authState } = useOktaAuth();
      // const token = authState?.accessToken?.accessToken || null;
      // Retrieve the entire Okta token storage object from local storage
      const oktaTokenStorage = localStorage.getItem("okta-token-storage");

      // Parse the token storage if it exists
      const parsedTokenStorage = oktaTokenStorage
        ? JSON.parse(oktaTokenStorage)
        : null;

      // Extract the access token from the parsed token storage
      const token = parsedTokenStorage?.accessToken?.accessToken || null;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    } catch (error) {
      // User is not authenticated or other error handling
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
