import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { Chatbot } from "../../../components";
import { useSubmissionsContext } from "../../../context/submissions";

const ChatbotRoomWrapper = styled(Box)`
  background-color: #494d5f;
  flex: 1;
  overflow-y: auto;
`;

export default function ChatbotRoom() {
  const { submission } = useSubmissionsContext();
  const messages = submission?.chatHistory;

  return (
    <ChatbotRoomWrapper>
      <Chatbot chatHistory={messages} />
    </ChatbotRoomWrapper>
  );
}
