import { useParams } from "react-router";
import ReactChatbotKit from "react-chatbot-kit";
import { createCustomMessage } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./index.css";

import { Header } from "./components";
import defaultConfig from "./config";
import MessageParser from "./config/MessageParser";
import ActionProvider from "./config/ActionProvider";
import SystemMessage from "./config/SystemMessage";
import Loader from "./Loader/Loader";
import MarkDownView from "./MarkDownView/MarkDownView";

const validateInput = (inputText: string) => {
  // TODO: add additional validation if needed
  return inputText.trim() !== "";
};

interface ChatbotProps {
  chatHistory?: any[];
}

function Chatbot({ chatHistory = [] }: ChatbotProps) {
  let { submissionId } = useParams();

  // const loadMessages = () => {
  //   if (chatHistory.length === 0) {
  //     return [];
  //   }
  //   const userAndBotMessages = chatHistory.filter(
  //     (msg) => msg.type != "system"
  //   );
  //   return userAndBotMessages;
  // };

  const autoGeneratedMessages = chatHistory.filter(
    (msg) => msg.type === "system"
  );

  const config = {
    ...defaultConfig,
    state: {
      submissionId,
    },
    initialMessages: autoGeneratedMessages.map((message) =>
      createCustomMessage(message.message, message.type, {
        loading: true,
        delay: 500,
        payload: message.message,
      })
    ),
    customMessages: {
      system: (props: any) => {
        return (
          <SystemMessage
            {...props}
            message={props.state.messages.find(
              (msg: any) => msg.payload === props.payload
            )}
          />
        );
      },
    },
    customComponents: {
      botAvatar: () => {
        return <div className="react-chatbot-kit-custom-avatar">VeriskBot</div>;
      },
      userAvatar: () => {
        return <div className="react-chatbot-kit-custom-avatar">You</div>;
      },
      header: () => <Header />,
      botChatMessage: (props: any) => {
        if (props.message === "Loading...") {
          return (
            <div className="react-chatbot-kit-chat-bot-message">
              <Loader />
            </div>
          );
        }
        return (
          <div className="react-chatbot-kit-chat-bot-message">
            <MarkDownView markdown={props.message} />
          </div>
        );
      },
    },
    customStyles: {
      chatButton: {
        backgroundColor: "#faf7f0",
      },
    },
  };

  return (
    <ReactChatbotKit
      config={config}
      messageParser={MessageParser}
      actionProvider={ActionProvider}
      // messageHistory={loadMessages()} //TODO: fix the message history issue
      disableScrollToBottom={true} // disable auto scroll to bottom for longer response
      placeholderText="Ask a question"
      validator={validateInput}
    />
  );
}

export default Chatbot;
