import { Box, Typography, List, ListItem, Link } from "@mui/material";
import styled from "@emotion/styled";

const AvailableReportsWrapper = styled(Box)`
  padding: 16px;
  background-color: #494d5f;
  flex: 1;
`;

const documents = [
  {
    id: 1,
    title: "BUR Report",
    filePath: "/files/building-underwriting-report.pdf",
  },
  {
    id: 2,
    title: "Engineering & Safety Reports",
    filePath: "/files/engineering-and-safety-service.pdf",
  },
];

interface Document {
  id: number;
  title: string;
  filePath: string;
}

interface DocumentLinkProps {
  document: Document;
}

const DocumentLink: React.FC<DocumentLinkProps> = ({ document }) => {
  const openFileInNewWindow = (filePath: string, fileName: string) => {
    window.open(filePath, "_blank", `width=800,height=600,title=${fileName}`);
  };

  return (
    <div>
      <Link
        key={document.id}
        onClick={() => openFileInNewWindow(document.filePath, document.title)}
        underline="none"
        sx={{ ":hover": { cursor: "pointer" } }}
      >
        <Typography variant="body1" color="white" sx={{ ml: 2 }}>
          {document.title}
        </Typography>
      </Link>
    </div>
  );
};

export default function AvailableReports() {
  return (
    <AvailableReportsWrapper>
      <Typography variant="h6" fontWeight="bold" sx={{ pt: 2, pb: 2 }}>
        Reports Available
      </Typography>

      <List>
        {documents.map((document) => (
          <ListItem
            key={document.id}
            sx={{
              backgroundColor: "#404453",
              borderRadius: "20px",
              marginBottom: "16px",
              padding: "8px",
            }}
          >
            <DocumentLink document={document} />
          </ListItem>
        ))}
      </List>
    </AvailableReportsWrapper>
  );
}
