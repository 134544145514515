// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";
import { AppToolbar, PageLoading } from "../../components";
import { useParams } from "react-router";
import { Box, Container, Grid, Toolbar } from "@mui/material";
import { useEffect } from "react";
import {
  AvailableReports,
  SubmissionHeader,
  UnderwritingProcess,
  ChatbotRoom,
} from "./components";
import { TOOLBAR_HEIGHT } from "../../constants";
import { useSubmissionsContext } from "../../context/submissions";

const content_height_css = `calc(100vh - ${TOOLBAR_HEIGHT}px)`;

const Content = styled(Box)`
  flexGrow: 1
  overflow: 'auto'
`;

type PageColumnProps = {
  showBorder?: boolean;
};

const PageColumn = styled(Grid, {
  shouldForwardProp: (prop: string) => !prop.startsWith("showBorder"),
})<PageColumnProps>`
  background-color: #434756;
  border-right: ${(props) => props.showBorder && "1px solid #878383"};
  height: ${content_height_css};
  display: flex;
  flex-direction: column;
`;

function SubmissionPage() {
  let { submissionId } = useParams();

  console.log(`submissionId: ${submissionId}`);

  const { fetchSubmission, isLoadingSubmission } =
    useSubmissionsContext();

  useEffect(() => {
    const fetchData = async (submissionId: string) => {
      try {
        await fetchSubmission(submissionId); 
      } catch (error) {
        console.log(error);
      }
    };
    if (submissionId) {
      fetchData(submissionId);
    }
  }, [fetchSubmission, submissionId]);

  // TODO: add logic if submissionId not found
  if(isLoadingSubmission) {
    return <PageLoading />
  }

  return (
    <Box>
      <AppToolbar />
      <Content component="main">
        <Toolbar />
        <Container maxWidth={false} disableGutters>
          <Grid container sx={{ height: content_height_css }}>
            <PageColumn item xs={3} showBorder>
              <UnderwritingProcess />
              <AvailableReports />
            </PageColumn>
            <PageColumn item xs={9}>
              <SubmissionHeader />
              <ChatbotRoom />
            </PageColumn>
          </Grid>
        </Container>
      </Content>
    </Box>
  );
}


export default SubmissionPage;
