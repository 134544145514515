import { useState } from "react";
import { styled, Grid, Box, Button, Popover, FormControl, InputLabel, MenuItem, TextField, Select } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSubmissionsContext } from "../../../context/submissions";
import { RAG_VERSION_SETTINGS } from "../../../constants";
import { RAGSettings } from "../../types";

function convertVersionToReadable(version: string) {
  return version
    .replace(/v_/gi, 'Version ')
    .replace(/_/g, '.');
}

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[700]
}));

export default function Header() {
  const { ragSettings, updateRAGSettings } = useSubmissionsContext();
  const { version, top_k } = ragSettings

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = ({ target: { name, value }}: any) => {
    let updatedRagSettings: RAGSettings = { ...ragSettings }
    if (name === 'version') {
      updatedRagSettings = { ...RAG_VERSION_SETTINGS.find((ragSettings) => ragSettings.version === value)! }
    } else if (name === 'top_k' && value > 0) {
      updatedRagSettings['top_k'] = Number(value)
    }
    updateRAGSettings(updatedRagSettings)
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'rag-settings-popover' : undefined;

  return (
    <Box>
      <Grid container justifyContent="flex-end">
        <StyledButton aria-describedby={id} size="small" onClick={handleShow} endIcon={<ExpandMoreIcon />}>
          {convertVersionToReadable(version)}
        </StyledButton>
      </Grid>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <FormControl sx={{ m: 1, width: 150 }} size="small">
          <InputLabel id="rag-settings-popover-label-version">Version</InputLabel>
          <Select
            labelId="rag-settings-popover-label-version"
            value={version}
            label="Version"
            name="version"
            onChange={handleChange}
            sx={{ mb: 3 }}
          >
            {RAG_VERSION_SETTINGS.map(({version }) => {
              return (
                <MenuItem value={version} key={version}>{convertVersionToReadable(version)}</MenuItem>
              )
            })}
          </Select>
          <TextField
            label="Top K"
            type="number"
            inputProps={{
              min: 1,
              step: 1,
            }}
            value={top_k}
            name="top_k"
            size="small"
            onChange={handleChange}
          />
        </FormControl>
      </Popover>
    </Box>
  )
}
