import { Box, List, ListItem, Typography } from "@mui/material";

export default function Overview() {
  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        90-day Overview
      </Typography>
      <List sx={{ listStyleType: "disc", pl: 4 }}>
        <ListItem sx={{ display: "list-item", padding: 0, fontSize: '20px' }}>
          <Typography component="span" fontSize="20px" fontWeight="bold" color="#9BF185">4 submissions effective</Typography> within the next 90 days (2 have missing items)
        </ListItem>
        <ListItem sx={{ display: "list-item", padding: 0, fontSize: '20px' }}>
          <Typography component="span" fontSize="20px" fontWeight="bold" color="#FFC148">1 renewal</Typography> coming in the next 90 days (1 missing items)
        </ListItem>
      </List>
    </Box>
  );
}
