class MessageParser {
  actionProvider: any;
  state: any;
  constructor(actionProvider: any, state: any) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message: any) {
    const lowercasedMessage = message.toLowerCase();
    // for testing
    // if (lowercasedMessage.includes("hello world")) {
    //   this.actionProvider.helloWorldHandler();
    // }
    this.actionProvider.messagePostHandler(lowercasedMessage, this.state.submissionId);
  }
}

export default MessageParser;
