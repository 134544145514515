import { RAGSettings, RAGVersion } from "./components/types";

export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const AUTH_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const AUTH_ISSUER = process.env.REACT_APP_ISSUER;

export const ENVIRONMENT = API_URL?.includes("accep") ? "accp" : (API_URL?.includes("test") ? "test" : "dev");
export const PAGE_TITLE = "Verisk GenAI Copilot" + (ENVIRONMENT === "accp" ? "" : ` (${ENVIRONMENT})`)
export const BOT_NAME = "VeriskBot";
export const TOOLBAR_HEIGHT = 64;

export const RAG_VERSION_SETTINGS: RAGSettings[] = [
  {
      "version": RAGVersion.V_1_3,
      "top_k": 2,
  },
  {
      "version": RAGVersion.V_1_4,
      "top_k": 1,
  }
]
