import { Box, Container, Grid, Paper, Toolbar } from '@mui/material'
import styled from '@emotion/styled'
import { AppToolbar } from '../../components'
import { Overview, PortfolioStatistics, Submissions } from './components'
import { TOOLBAR_HEIGHT } from '../../constants'

const GRID_SPACING = 4

const Content = styled(Box)`
  flexGrow: 1
  height: calc(100vh - ${TOOLBAR_HEIGHT}px);
  overflow: 'auto'
`

const ContentBlock = styled(Paper)`
  background: #434756;
  padding: 20px;
  border-radius: 20px;
  height: 100%;
`

function HomePage() {
  return (
    <Box>
      <AppToolbar />
      <Content component="main">
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: GRID_SPACING, mb: GRID_SPACING }}>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <ContentBlock>
                <Overview />
              </ContentBlock>
            </Grid>
            <Grid item xs={8}>
              <ContentBlock>
                <Submissions />
              </ContentBlock>
            </Grid>
            <Grid item xs={4}>
              <ContentBlock>
                <PortfolioStatistics />
              </ContentBlock>
            </Grid>
          </Grid>
        </Container>
      </Content>
    </Box>
  )
}

export default HomePage
