import {
  createContext,
  PropsWithChildren,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import { RAGSettings, Submission } from "../../components/types";
import { getSubmissions, getSubmission } from "../../api/submissions";
import { RAG_VERSION_SETTINGS } from "../../constants";

type SubmissionsContextProps = {
  submissions: Submission[];
  fetchSubmissions: () => Promise<void>;
  isLoadingSubmissions: boolean;

  submission: Submission | undefined;
  fetchSubmission: (submissionId: string) => Promise<void>;
  isLoadingSubmission: boolean;

  ragSettings: RAGSettings;
  updateRAGSettings: (ragSettings: RAGSettings) => void;
};

const SubmissionsContext = createContext<SubmissionsContextProps>(
  {} as SubmissionsContextProps
);

const DEFAULT_RAG_SETTINGS = RAG_VERSION_SETTINGS[0] // defaults to version 1.3

function useSubmission() {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [isLoadingSubmissions, setLoadingSubmissions] = useState<boolean>(true);

  const [submission, setSubmission] = useState<Submission>();
  const [isLoadingSubmission, setLoadingSubmission] = useState<boolean>(true);

  const [ragSettings, setRagSettings] = useState<RAGSettings>(DEFAULT_RAG_SETTINGS);

  const fetchSubmissions = useCallback(async () => {
    setLoadingSubmissions(true);
    const response = await getSubmissions();
    setSubmissions(response.data);
    setLoadingSubmissions(false);
  }, []);

  const fetchSubmission = useCallback(async (submissionId: string) => {
    setLoadingSubmission(true);
    const response = await getSubmission(submissionId);
    setSubmission(response.data);
    setLoadingSubmission(false);
  }, []);

  const updateRAGSettings = useCallback((ragSettings: RAGSettings) => {
    setRagSettings({ ...ragSettings });
    const url: URL = new URL(window.location.href);
    url.searchParams.set('version', ragSettings.version);
    url.searchParams.set('top_k', String(ragSettings.top_k));
    // window.history.pushState(null, '', url.toString());
    window.history.replaceState(null, '', url.toString());
  }, []);

  useEffect(() => {
    const url: URL = new URL(window.location.href);
    const isOnSubmissionPage = /\/submissions/i.test(url.toString());
    if (isOnSubmissionPage) {
      const version = url.searchParams.get('version');
      const top_k = Number(url.searchParams.get('top_k'));
      const ragSettings = RAG_VERSION_SETTINGS.find(settings => settings.version === version) || DEFAULT_RAG_SETTINGS
      if (top_k > 0) {
        ragSettings.top_k = top_k
      }
      updateRAGSettings(ragSettings)
    }
  }, [updateRAGSettings]);

  return {
    submissions,
    fetchSubmissions,
    isLoadingSubmissions,
    submission,
    fetchSubmission,
    isLoadingSubmission,
    ragSettings,
    updateRAGSettings,
  };
}

export function SubmissionsProvider({ children }: PropsWithChildren<{}>) {
  const {
    submissions,
    fetchSubmissions,
    isLoadingSubmissions,
    submission,
    fetchSubmission,
    isLoadingSubmission,
    ragSettings,
    updateRAGSettings,
  } = useSubmission();

  return (
    <SubmissionsContext.Provider
      value={{
        submissions,
        fetchSubmissions,
        isLoadingSubmissions,
        submission,
        fetchSubmission,
        isLoadingSubmission,
        ragSettings,
        updateRAGSettings,
      }}
    >
      {children}
    </SubmissionsContext.Provider>
  );
}

export function useSubmissionsContext() {
  return useContext(SubmissionsContext);
}
