import { styled } from "@mui/material/styles";
import { FC, ReactNode } from "react";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

type Props = {
  className?: string;
  children?: ReactNode;
  markdown: string;
};

const SC = {
  // Can add more styles here if needed
  // Presever the new line \n
  P: styled("p")(({ theme }) => ({
    whiteSpace: "pre-line",
    ':first-of-type': {
      marginTop: 0,
    },
    ':last-of-type': {
      marginBottom: 0,
    }
  })),
};

const MarkDownView: FC<Props> = React.memo(function MarkdownView({
  className,
  markdown,
}) {
  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[remarkGfm]}
      components={{
        p({ className, children, ...props }) {
          return <SC.P className={className}>{children}</SC.P>;
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
});

export default MarkDownView;
