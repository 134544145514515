import {
  Box,
  Grid,
  Paper,
  styled,
  Typography,
  Tooltip,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useSubmissionsContext } from "../../../context/submissions";

const redColor = "#FF6363";
const greenColor = "#9BF185";

const fieldNameMapping = {
  constructionType: "Construction Type",
  totalInsuredValue: "Total Insured Value",
  buildingValue: "Building Value",
  totalLoss: "Total Loss",
  accountName: "Account Name",
  streetAddress: "Street Address",
  lossDate: "Loss Date",
  buildingDescription: "Building Description",
  buildingLoss: "Building Loss",
  naic: "NAIC",
};

const StyledItem = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  backgroundImage: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

interface StyledTypographyProps {
  checkStatus: boolean;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop: string) => !prop.startsWith("checkStatus"),
})<StyledTypographyProps>(({ theme, ...props }) => ({
  ...theme.typography.h6,
  color: props.checkStatus ? greenColor : redColor,
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "0.8em",
    color: "#ffffff",
    backgroundColor: "#17181A",
    placement: "top",
    maxWidth: "650px",
    maxHeight: "300px",
    overflow: "auto",
  },
});

export default function SubmissionHeader() {
  const { submission } = useSubmissionsContext();

  if (!submission) {
    return null;
  }

  const getCheckStatus = (check: string) => {
    const submissionCheck = JSON.parse(
      JSON.stringify(submission[check as keyof typeof submission])
    );
    switch (check) {
      case "documentReadiness":
        return submissionCheck["isComplete" as keyof typeof submissionCheck];
      case "replacementCost":
        if (submissionCheck["error"]) {
          return false;
        }
        return submissionCheck["isAcceptable" as keyof typeof submissionCheck];
      case "riskAppetite":
        if (submissionCheck["error"]) {
          return false;
        }
        return submissionCheck["isFit" as keyof typeof submissionCheck];
    }
  };

  const renderTableRows = (
    requiredFields: [Record<string, any>],
    check: string
  ) => {
    return requiredFields.map(({ field, isComplete, isFit, source, rule }) => {
      const mappedFieldName =
        fieldNameMapping[field as keyof typeof fieldNameMapping] || field;
      const value =
        JSON.parse(
          JSON.stringify(submission[source as keyof typeof submission])
        )[field] || "Missing Data";
      const isPass = check === "documentReadiness" ? isComplete : isFit;
      return (
        <TableRow key={field} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            {isPass ? (
              <CheckIcon style={{ color: greenColor }} />
            ) : (
              <ClearIcon style={{ color: redColor }} />
            )}
          </TableCell>
          <TableCell>{mappedFieldName}</TableCell>
          <TableCell
            style={{ color: value === "Missing Data" ? redColor : "inherit" }}
          >
            {check === "documentReadiness" ? value : rule}
          </TableCell>
        </TableRow>
        // <ListItem key={fieldName}>
        //   <ListItemText
        //     primary={
        //       <Box display="flex" alignItems="center">
        //         <span>{fieldName}</span>
        //         <span style={{ marginLeft: "8px" }}>{value}</span>
        //         <ListItemIcon>
        //           {isComplete ? (
        //             <CheckIcon style={{ color: "green" }} />
        //           ) : (
        //             <ClearIcon style={{ color: "red" }} />
        //           )}
        //         </ListItemIcon>
        //       </Box>
        //     }
        //   />
        // </ListItem>
      );
    });
  };

  const getTooltipTitle = (check: string) => {
    const submissionCheck = JSON.parse(
      JSON.stringify(submission[check as keyof typeof submission])
    );
    if (submissionCheck["error"]) {
      return (
        <Box sx={{ display: "flex", maxWidth: "350px", p: 1 }}>
          <ErrorRoundedIcon sx={{ mr: 1, color: redColor, fontSize: "1rem" }} />
          {submissionCheck["error"]}
        </Box>
      );
    }
    const { requiredFields } = submissionCheck || {};
    // const { requiredFields } = allFields;
    // const listItems = renderListItems(requiredFields);

    // const listItems = renderListItems(
    // documentReadiness["requiredFields"] || []
    // );

    return (
      // <List>
      //   {listItems.length === 0 ? (
      //     <ListItem>
      //       <ListItemText primary="No required fields" />
      //     </ListItem>
      //   ) : (
      //     listItems
      //   )}
      // </List>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                {check === "documentReadiness" ? "Status" : "Fitness"}
              </TableCell>
              <TableCell>Field Name</TableCell>
              <TableCell>
                {check === "documentReadiness" ? "Value" : "Rule"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requiredFields.length === 0 ? (
              <TableRow>
                <TableCell colSpan={1} align="center">
                  No required fields
                </TableCell>
              </TableRow>
            ) : (
              renderTableRows(requiredFields, check)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getTooltipTitle360Check = (check: string) => {
    const replacementCost = JSON.parse(
      JSON.stringify(submission[check as keyof typeof submission])
    );
    if (replacementCost["error"]) {
      return (
        <Box sx={{ display: "flex", maxWidth: "350px", p: 1 }}>
          <ErrorRoundedIcon sx={{ mr: 1, color: redColor, fontSize: "1rem" }} />
          {replacementCost["error"]}
        </Box>
      );
    }
    const isAcceptable = replacementCost["isAcceptable"];
    return (
      <Box sx={{ display: "flex", maxWidth: "350px", p: 1 }}>
        {isAcceptable ? (
          <CheckCircleRoundedIcon
            sx={{ mr: 1, color: greenColor, fontSize: "1rem" }}
          />
        ) : (
          <ErrorRoundedIcon sx={{ mr: 1, color: redColor, fontSize: "1rem" }} />
        )}
        {replacementCost["rationale"]}
      </Box>
    );
  };

  const getTooltip = (check: string) => {
    const tooltipTitle =
      check === "replacementCost"
        ? getTooltipTitle360Check(check)
        : getTooltipTitle(check);
    // TODO: shrink the width for error message
    return (
      <CustomTooltip
        title={tooltipTitle}
        placement="bottom"
      >
        <InfoOutlinedIcon sx={{ ml: 1, color: "#ffffff", fontSize: "1rem" }} />
      </CustomTooltip>
    );
  };

  return (
    <Box sx={{ m: 2, mr: 16, ml: 16 }}>
      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={6}>
          <StyledItem>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DescriptionIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ mr: 1 }}>
                Document Readiness:
              </Typography>
              <StyledTypography
                checkStatus={getCheckStatus("documentReadiness")}
              >
                {getCheckStatus("documentReadiness")
                  ? "Complete"
                  : "Incomplete"}
              </StyledTypography>
              {getTooltip("documentReadiness")}
            </Box>
          </StyledItem>
        </Grid>
        <Grid item xs={6}>
          <StyledItem>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ mr: 1 }}>
                Account:
              </Typography>
              <Typography
                variant="h6"
                color={submission.accountName ? "inherit" : redColor}
              >
                {submission.accountName ? submission.accountName : "Missing"}
              </Typography>
            </Box>
          </StyledItem>
        </Grid>
        <Grid item xs={6}>
          <StyledItem>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TrackChangesIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ mr: 1 }}>
                Risk Appetite:
              </Typography>
              <StyledTypography checkStatus={getCheckStatus("riskAppetite")}>
                {getCheckStatus("riskAppetite") ? "Fit" : "Not Fit"}
              </StyledTypography>
              {getTooltip("riskAppetite")}
            </Box>
          </StyledItem>
        </Grid>
        <Grid item xs={6}>
          <StyledItem>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ApartmentIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ mr: 1 }}>
                Replacement Cost:
              </Typography>
              <StyledTypography checkStatus={getCheckStatus("replacementCost")}>
                {getCheckStatus("replacementCost")
                  ? "Acceptable"
                  : "Not Acceptable"}
              </StyledTypography>
              {getTooltip("replacementCost")}
            </Box>
          </StyledItem>
        </Grid>
      </Grid>
    </Box>
  );
}
